import {
  ActivityOrderField,
  ActivitySearchOrderType,
  CommunityPostSearchOrderField,
  CoverLetterOrderField,
  RecruitOrganizationType,
  JobType,
  UserDeleteReasonType,
  AnalysisReportOrderField,
} from 'generated/graphql'
import { ActivityTypeID, ActivityTypeText } from './enums'

export const ORDER_TEXT: {
  [key in ActivityOrderField]: string
} = {
  [ActivityOrderField.CREATED_AT]: '생성일순',
  [ActivityOrderField.RECRUIT_CLOSE_AT]: '마감순',
  [ActivityOrderField.RECENT]: '최신순',
  [ActivityOrderField.SCRAP_COUNT]: '최근 스크랩 증가순',
  [ActivityOrderField.VIEW_COUNT]: '인기순',
  [ActivityOrderField.REPLY_COUNT]: '지원자순',
  [ActivityOrderField.DEAD_LINE]: '마감일순',
  [ActivityOrderField.STATUS_UPDATED_AT]: '수정일순',
}

export const ACTIVITY_LIST_ORDER_TEXT: {
  [key in Exclude<
    ActivityOrderField,
    'DEAD_LINE' | 'RECENT' | 'STATUS_UPDATED_AT'
  >]: string
} = {
  [ActivityOrderField.CREATED_AT]: '최신순',
  [ActivityOrderField.SCRAP_COUNT]: '최근 스크랩 증가순',
  [ActivityOrderField.VIEW_COUNT]: '최근 조회 증가순',
  [ActivityOrderField.REPLY_COUNT]: '최근 댓글 증가순',
  [ActivityOrderField.RECRUIT_CLOSE_AT]: '마감순',
}

export const ACTIVITY_POST_SEARCH_ORDER_TEXT: {
  [key in ActivitySearchOrderType]: string
} = {
  [ActivitySearchOrderType.CREATED_AT]: '최신순',
  [ActivitySearchOrderType.VIEW_COUNT]: '인기순',
  [ActivitySearchOrderType.SCRAP_COUNT]: '스크랩순',
  [ActivitySearchOrderType.RECRUIT_CLOSEAT]: '마감순',
  [ActivitySearchOrderType.RELEVANCE]: '관련도순',
}

export const COMMUNITY_POST_SEARCH_ORDER_TEXT: {
  [key in CommunityPostSearchOrderField]: string
} = {
  [CommunityPostSearchOrderField.CREATED_AT]: '최신순',
  [CommunityPostSearchOrderField.VIEW_COUNT]: '인기순',
  [CommunityPostSearchOrderField.RELEVANCE]: '관련도순',
}

export const COVER_LETTER_SEARCH_ORDER_TEXT = {
  [CoverLetterOrderField.RELEVANCE]: '관련도순',
  [CoverLetterOrderField.SCRAP_COUNT]: '스크랩순',
  [CoverLetterOrderField.PASSED_AT]: '최신순',
}

export const COMPANY_ANALYSIS_ORDER_TEXT: {
  [key in AnalysisReportOrderField]: string
} = {
  [AnalysisReportOrderField.CREATED_AT]: '최근 등록순',
  [AnalysisReportOrderField.IMPRESSION_COUNT]: '조회순',
  [AnalysisReportOrderField.SCRAP_COUNT]: '스크랩순',
}

export const ORGANIZATIONS: {
  [key in RecruitOrganizationType]: string
} = {
  [RecruitOrganizationType.MAJOR_COMPANY]: '대기업',
  [RecruitOrganizationType.SMALL_BUSINESS]: '중소기업',
  [RecruitOrganizationType.PUBLIC_INSTITUTIONS]: '공공기관/공기업',
  [RecruitOrganizationType.FOREIGN_COMPANIES]: '외국계기업',
  [RecruitOrganizationType.MIDSIZE_BUSINESS]: '중견기업',
  [RecruitOrganizationType.NON_PROFIT_ORGANIZATION]: '비영리단체/협회/재단',
  [RecruitOrganizationType.START_UP]: '스타트업',
  [RecruitOrganizationType.BANK]: '금융권',
  [RecruitOrganizationType.HOSPITAL]: '병원',
  [RecruitOrganizationType.CLUB]: '동아리/학생자치단체',
  [RecruitOrganizationType.ETC]: '기타',
}

export const JOBTYPE: {
  [key in JobType]: string
} = {
  [JobType.NEW]: '신입',
  [JobType.INTERN]: '인턴',
  [JobType.EXPERIENCED]: '경력직',
  [JobType.CONTRACT]: '계약직',
}

/**
 * 네이버 서치어드바이저 `experienceRequirements` 가이드에 맞추어 인턴, 신입을 모두 **신입**으로 통일하고 경력직을 **경력**으로 통일합니다.
 *
 * https://searchadvisor.naver.com/guide/structured-data-job
 *
 * TODO : 추후 JOBTYPE을 다른곳에서 사용하지 않는다면 해당 상수를 삭제하고 JOBTYPE_JSONLD를 사용하는 곳에서 JOBTYPE을 사용하도록 변경합니다.
 */
export const JOBTYPE_JSONLD: {
  [key in JobType]: string
} = {
  [JobType.NEW]: '신입',
  [JobType.INTERN]: '신입',
  [JobType.EXPERIENCED]: '경력',
  [JobType.CONTRACT]: '계약직',
}

export const ACTIVITY_TYPE_TEXT: {
  [key in ActivityTypeID]: string
} = {
  [ActivityTypeID.ACTIVITY]: '대외활동',
  [ActivityTypeID.CLUB]: '동아리',
  [ActivityTypeID.CONTEST]: '공모전',
  [ActivityTypeID.INTERN]: '인턴',
  [ActivityTypeID.RECRUIT]: '채용',
  [ActivityTypeID.EDUCATION]: '교육',
}

export const ACTIVITY_TYPE_ID_NAME: {
  [key in ActivityTypeID]: ActivityTypeText
} = {
  [ActivityTypeID.ACTIVITY]: ActivityTypeText.ACTIVITY,
  [ActivityTypeID.CLUB]: ActivityTypeText.CLUB,
  [ActivityTypeID.CONTEST]: ActivityTypeText.CONTEST,
  [ActivityTypeID.INTERN]: ActivityTypeText.INTERN,
  [ActivityTypeID.RECRUIT]: ActivityTypeText.RECRUIT,
  [ActivityTypeID.EDUCATION]: ActivityTypeText.EDUCATION,
}

export const ACTIVITY_TYPE_TEXT_ID: {
  [key in ActivityTypeText]: ActivityTypeID
} = {
  [ActivityTypeText.ACTIVITY]: ActivityTypeID.ACTIVITY,
  [ActivityTypeText.CLUB]: ActivityTypeID.CLUB,
  [ActivityTypeText.CONTEST]: ActivityTypeID.CONTEST,
  [ActivityTypeText.INTERN]: ActivityTypeID.INTERN,
  [ActivityTypeText.RECRUIT]: ActivityTypeID.RECRUIT,
  [ActivityTypeText.EDUCATION]: ActivityTypeID.EDUCATION,
}

export const USER_DELETE_REASON_TYPE_TEXT: {
  [key in UserDeleteReasonType]: string
} = {
  [UserDeleteReasonType.LOW_FREQUENCY_USES]: '사용빈도 낮음',
  [UserDeleteReasonType.POOR_UX_UI]: '사이트 이용 불편',
  [UserDeleteReasonType.LACK_OF_CONTENT_QUALITY]: '콘텐츠 내용 부족',
  [UserDeleteReasonType.OUT_OF_JOB_MARKET]: '대외활동/인턴/구직활동 종료',
  [UserDeleteReasonType.POOR_SECURITY]: '개인정보 유출 우려',
  [UserDeleteReasonType.ETC]: '기타',
}
